import React from 'react'


const Profile = () =>{

return (
    <h1>Profile</h1>
)







}
export default  Profile; 