import React, { useState, useEffect } from "react";
import "./App.css";
import { Route, BrowserRouter, Routes, HashRouter } from "react-router-dom";
import { Layout, ConfigProvider } from "antd";
import enUS from "antd/lib/locale-provider/en_US";
import { useJwt, decodeToken } from "react-jwt";
import WelcomeScreen from "./Screens/WelcomeScreen";
import { Nav } from "./Nav";
import { UserContext } from "./globalContext";

import { Footers } from "./Footers"; 
import LoginScreen from "./Screens/LoginScreen";
import SignupScreen from "./Screens/SignupScreen";
import HomeScreen from "./Screens/HomeScreen";
import RechargeAdmin from "./Screens/LudoAdmin/Recharge";
import WithdrawAdmin from "./Screens/LudoAdmin/Withdraw"
import MemberListAdmin from "./Screens/Member/ActiveMemberListScreen";
import DeletedMemberList from "./Screens/Member/DeletedMemberList";
import UpiScreen from "./Screens/LudoAdmin/Upi/UpiScreen";
import CancelGameList from "./Screens/Game1/CancelGame";
import CancelGame2List from "./Screens/Game2/CancelGame2";
import SuccessGame2List from "./Screens/Game2/SuccessGame2";
import SuccessGameList from "./Screens/Game1/SuccessGame";
import ReportedGameList from "./Screens/Game1/reportedGame1";
import SolveGame1 from "./Screens/Game1/solvedReport";
import ReportedGameList2 from "./Screens/Game2/reportedGame2";
import SolveGame2 from "./Screens/Game2/solvedReport2";
import Profile from "./profile";
import ReportedCGameList from "./Screens/Game1/reportedCGame1";
import SolveCGame1 from "./Screens/Game1/solvedCReport";
import ReportedCGameList2 from "./Screens/Game2/reportedCGame2";
import SolveCGame2 from "./Screens/Game2/solvedCReport2";
import FastParityBat from "./Screens/Game1/fastParityBat";
import ParityBat from "./Screens/Game1/parityBat";
import ParityResult from "./Screens/Game1/parityResult";
import FastParityResult from "./Screens/Game1/FastParityResult";
import Profittable from "./Screens/Game1/profittable";
import UpiScreenAct from "./Screens/LudoAdmin/UpiAdd";









require("./css/App-dev.css");
require("./css/responsive.css");
require("./Screens/login.css");

function App() {
  const [waitstate, setWaitstate] = useState(true);
  const [authToken, setAuthToken] = useState(null);
  // const { authToken, isExpired } = useJwt(JSON.parse(localStorage.getItem("authtoken")));

  useEffect(() => {

    if (
      localStorage.getItem("authtoken") !== null &&
      localStorage.getItem("authtoken") !== undefined
    ) {

      const myDecodedToken = decodeToken(JSON.parse(localStorage.getItem("authtoken")));
if(myDecodedToken.role===undefined){
  myDecodedToken.role="test"
  setAuthToken(myDecodedToken);
}
else{
  setAuthToken(myDecodedToken);
}
     
      setWaitstate(false);
      localStorage.setItem("username", JSON.parse(localStorage.getItem("authtoken")).name)
    } else {
      setWaitstate(false);
      setAuthToken(null);
     // window.location.href = "/";
      //navigate("/LoginScreen", { replace: true });

    }
  }, []);
  const validation = () => {

    const myDecodedToken = decodeToken(JSON.parse(localStorage.getItem("authtoken")));
    setAuthToken(myDecodedToken);
  }


  return (
    <HashRouter>
      <div className="App">
        {!waitstate && (
          <UserContext.Provider
            value={
              authToken !== null
                ? {
                  userId: authToken._id === undefined ? 1 : authToken._id,
                  userName: authToken.name === undefined ? 1 : authToken.name,

                  memberId: authToken.id === undefined ? 1 : authToken.id,
                  role: authToken.role === undefined ? 1 : authToken.role.name,
                  mobile: authToken.mobile === undefined ? 1 : authToken.mobile,
                  memberReferCode: authToken.memberReferCode === undefined ? 1 : authToken.memberReferCode,
                  roleId: authToken.role === undefined ? 1 : authToken.role._id,
                  registerDate: authToken.registerDate === undefined ? 1 : authToken.registerDate,
                  config: {
                    dateFormat: "DD-MMM-YYYY",
                    datetimeFormat: "DD-MMM-YYYY HH:mm",
                    datetimeFormatWithoutYear: "DD-MMM HH:mm",
                    datetimeSecondFormat: "DD-MMM-YYYY HH:mm:ss",
                    datetimeSecondFormatAMPM: "DD-MMM-YYYY hh:mm:ss A",
                    timeFormat: "hh:mm:ss A",
                  }
                }
                : {
                  userId: null,

                  config: {
                    dateFormat: "DD-MMM-YYYY",
                    datetimeFormat: "DD-MMM-YYYY HH:mm",
                    datetimeFormatWithoutYear: "DD-MMM HH:mm",
                    datetimeSecondFormat: "DD-MMM-YYYY HH:mm:ss",
                    datetimeSecondFormatAMPM: "DD-MMM-YYYY hh:mm:ss A",
                    timeFormat: "hh:mm:ss A",
                  }
                }
            }
          >
            <Layout className="layout">
            {/* {(authToken== undefined) && (authToken== null) && <Nav2/>} */}
              {(authToken && authToken.id !== undefined) && (authToken && authToken.id !== null) && <Nav authToken={authToken} />}
              <ConfigProvider locale={enUS}>
                <Routes>
                  <Route path="/" element={<LoginScreen />} />
                  <Route path="LoginScreen" element={<LoginScreen />} />
                  <Route path="MeenaAkshayCreate223" element={<SignupScreen authToken={authToken} />} />

                  {/* <Route path="Dummy" element={<PrintList/>}/> */}
                  <Route path="HomeScreen" element={<HomeScreen ppp={true} />} />
{/* <Admin Route> */}


                  <Route path="ARecharge" element={<RechargeAdmin />} />
                  <Route path="AWithdraw" element={<WithdrawAdmin />} />
                  <Route path="member" element={<MemberListAdmin />} />
                  <Route path="deletedMember" element={<DeletedMemberList />} />
                  <Route path="upi" element={<UpiScreenAct />} />
                  <Route path="cGame1" element={<CancelGameList />} />
                  <Route path="sGame1" element={<SuccessGameList />} />
                  <Route path="fGame1" element={<ReportedGameList />} />
                  <Route path="fCGame1" element={<ReportedCGameList />} />
                  <Route path="fGame2" element={<ReportedGameList2 />} />
                  <Route path="fCGame2" element={<ReportedCGameList2 />} />
                  <Route path="solveGame1/:data" element={<SolveGame1 />} />
                  <Route path="solveCGame1/:data" element={<SolveCGame1 />} />
                  <Route path="solveGame2/:data" element={<SolveGame2 />} />
                  <Route path="solveCGame2/:data" element={<SolveCGame2 />} />
                  <Route path="cGame2" element={<CancelGame2List />} />
                  <Route path="sGame2" element={<SuccessGame2List />} />
                  <Route path="fastParityBat" element={<FastParityBat />} />
                  <Route path="parityBat" element={<ParityBat />} />
                  <Route path="parityResult" element={<ParityResult />} />
                  <Route path="fastparityResult" element={<FastParityResult />} />
                  <Route path="profittable" element={<Profittable />} />









                  <Route path="profile" element={<Profile />} />

               
 {/* <Admin Route> */}       
              
                 
          
                  
                
              
                </Routes>
              </ConfigProvider>
              {/* <Footers /> */}
            </Layout>
          </UserContext.Provider>
        )}
      </div>
    </HashRouter>
  );
}

export default App;
