import React, {useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {Layout, Menu, Dropdown} from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import {environment} from './Environment';
import iconUser from '../src/images/Icon-user.svg';
import Icon from '@ant-design/icons';
import Hamburger from 'hamburger-react';
import {useState} from 'react';

// import wollet from "./static/media/wallet.png"



// import mainlogo from'./static/media/avatar-f-2.c30ca059e863004ac5f7e22dcb211721.svg',

import {useNavigate} from 'react-router-dom';
import { blueGrey } from '@mui/material/colors';
import axiosInstance from './axiosInstance';

const {Header} = Layout;

export const Nav = props => {
  let navigate = useNavigate();
 
  const [allProfit , setAllProfit] = useState()

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAllProfit();
    }
    return () => (mounted = false);
    
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = `/`;
  };

  const myaccount = () => {
    navigate('/MyAccount', {replace: true});
  };

  const getAllProfit = () => {
        
    axiosInstance.post(`/profit/report`).then((response) => {
console.log("allProfit",response.data.data)
        if(response.data.data.length>0){
            setAllProfit(...response.data.data);
        }
        else{
            setAllProfit({amount:0})
        }
       
    });
}




  document.querySelectorAll('.dropdown-toggle').forEach(item => {
    item.addEventListener('click', event => {
      if (event.target.classList.contains('dropdown-toggle')) {
        event.target.classList.toggle('toggle-change');
      } else if (
        event.target.parentElement.classList.contains('dropdown-toggle')
      ) {
        event.target.parentElement.classList.toggle('toggle-change');
      }
    });
  });

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleToggleClick = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };




  return (
    <></>
  );
};
