import React, { useState, useContext, useEffect } from "react";
import { Col, Form, Row, Input, Button, message, Modal, Image } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import axiosInstance from "../axiosInstance";
import { UserContext } from "../globalContext";
import { useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';
// import React, {useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {Layout, Menu, Dropdown} from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
// import {environment} from './Environment';
import iconUser from '../../src/images/Icon-user.svg';
// import logo from '../src/images/logo.jpg';
import Icon from '@ant-design/icons';
import Hamburger from 'hamburger-react';
// import {useState} from 'react';

// import wollet from "../static/media/wallet.png"


import ForgotPasswordModel from "./ForgotPassword.model";
import { Container } from "react-bootstrap";

const LoginScreen = () => {
  let navigate = useNavigate();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen1, setIsModalOpen1] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = useContext(UserContext);
  useEffect(() => {

    let mounted = true;
    if (mounted) {

      
      if (user.userId !== null) {
        // navigate("/HomeScreen", { replace: true });
        localStorage.clear();
        window.location.reload();
      }


    }
    return () => (mounted = false);
  }, []);

  const onCancel = () => {
    setIsModalOpen(false);
  };
  const onAdd = () => {
    setIsModalOpen(false);
  };

  const onSignin = () => {
  

    axiosInstance
      .post("/masterLogin", { mobile: mobile, password: password })
      .then((res) => {
       console.log("res",res)
        if (res.data) {
          if(res.data.responseCode === 1){
           
            localStorage.setItem("authtoken", JSON.stringify(res.data.auth));
 navigate(`/HomeScreen`, { replace: true });
           // window.location.href = "#/HomeScreen";
  
          }
          if(res.data.responseCode === -2){
            message.error("Your account is temporarily frozen");
          }
          if(res.data.responseCode === -1){
            message.error("please enter correct mobile number or password");
          }

        } else message.error("please enter correct mobile number or password");
      })
      .catch((err) => {
        message.error("please enter correct mobile number or password");
      });
  };
  const onSignup = () => {
    navigate('/SignupScreen', { replace: true });
    // window.location.href= `/SignupScreen`;
  };

  const onForgotPassword = () => {
    setIsModalOpen(true);
    // navigate('/SSignupScreen', { replace: true });

  };


  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleToggleClick = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  

  return (
  <div className="d-flex justify-content-center align-items-center" style={{height:'100vh'}}>

<div class="form_main " >
    <p class="h4 text-decoration-underline mb-4">Admin Login</p>
    <div class="inputContainer">
     
        <MDBInput class="inputField" label='Mobile Number' onChange={(e) => setMobile(e.target.value)} id='formControlLg' type='number' size="lg"/>

    </div>
    
<div class="inputContainer">
  
    <MDBInput class="inputField" label='Password' id='formControlLg' onChange={(e) => setPassword(e.target.value)} type='password' size="lg"/>
</div>
              
           
<MDBBtn onClick={() =>onSignin()} className="mb-4 w-100" style={{height:"50px"}} size="lg">Sign in</MDBBtn>

  
</div>


   

  </div>

  );
};

export default LoginScreen;
